import './App.css';
// import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './views/HomePage';
import Products from './views/Products';
import ProductDetails from './views/ProductDetails';
import NotFound from './views/404';
import ComingSoon from './views/Comingsoon';
import Header from './components/header/Header';
import Menu from './components/menu/Menu';
import MobileMenu from './components/menu/MobileMenu';
import Footer from './components/footer/Footer';
import { Image } from "react-bootstrap";
import WhatsappIcon from './assets/whatsapp.png'
import ThankYouPage from './views/ThankYou';
function App() {
  return (
    <>
    <Router>
      <section className="desktop-menu">
        <Header />
        <Menu />
      </section>
      <section className="mobile-menu">
        <MobileMenu />
      </section>
        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
        <Switch>
          <Route  exact path="/" component={HomePage} />
            <Route exact path={process.env.PUBLIC_URL+'/product/:category'} component={Products} />
            <Route exact path={process.env.PUBLIC_URL+'/product/:category/:id'} component={ProductDetails} />
            <Route exact  path={process.env.PUBLIC_URL+'/coming-soon'} component={ComingSoon} />
            <Route exact  path={process.env.PUBLIC_URL+'/thank-you'} component={ThankYouPage} />
          <Route path='*' component={NotFound}/>
        </Switch>
        <Footer />
        <section className="static-whatsapp">
          <div className="whatsapp-icon" onClick={()=>window.open('https://api.whatsapp.com/send/?phone=18328460004&text=I%27m+Looking+for+gold+jewlery&type=phone_number&app_absent=0','__blank')}>
            <Image src={WhatsappIcon} alt="whatsapp-icon" title="Whatsapp-icon" />
          </div>
        </section>
      </Router>
    </>
  );
}

export default App;
