import { Col, Container, Image, Row, Button } from "react-bootstrap";
import ThankYou from '../assets/thank-you.svg';
import './ThankYou.scss';
import WhatsappIcon from '../assets/whatsapp.png'
export default function ThankYouPage() {
    return(
        <section className="thank-you">
            <Container>
                <Row className="justify-center">
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="icon-image">
                            <Image src={ThankYou} alt="ThankYou" title="ThankYou" className="w-100" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <section className="thankyou-content-block text-center">
                <Container>
                    <div>
                        <h2 className="section-title">Thank You</h2>
                        <p>Your submission has been received. <br/>We will be in touch and contact you soon.</p>
                    </div>
                </Container>
            </section>
            <section className="thankyou-footer">
            <div className="button mt-3 text-center mb-3">
                <Button onClick={()=>window.location.href=`/`} className="btn btn-submit">Back to Home</Button>
            </div>
            <div className="mt-3 book-appointment-icons text-center">
                <p>Or you can reach out to us on <strong>Whatsapp</strong> too.</p>
                <div className="whatsapp-book-icon">
                    <Image src={WhatsappIcon} alt="WhatsappIcon" title="WhatsappIcon" />
                    <span><a href="http://wa.me/18328460004?text=I%27m%20Looking%20for%20gold%20jewlery" target="_blank" rel="noreferrer">http://wa.me/18328460004?text=I%27m%20Looking%20for%20gold%20jewlery</a></span>
                </div>
            </div>
            </section>
        </section>
    )
}