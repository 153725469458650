import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import './Product.scss'
export default function NotFound(props) {
   useEffect(()=>{
        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    },[])
    return(
        <>
        <section className="products">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                       <h4 className="section-title text-captilize text-center">404 Page not found</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
    )
}
