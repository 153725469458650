import { Nav, Container, Navbar, NavDropdown, Image } from "react-bootstrap";
import './Menu.scss';
import RightIcon from '../../assets/right-arrow.png'
import { useState } from "react";
function Menu() {
    const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}
    return(
        <Navbar bg="transparent" expand="lg">
        <Container>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                navbarScroll
            >
                <Nav.Link href={process.env.PUBLIC_URL+'/#shop'}>Shop</Nav.Link>
                <NavDropdown title="Collections" className="dropdown" show={show}
                onMouseEnter={showDropdown} 
                onMouseLeave={hideDropdown}>
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/earrings'}><span>Earings</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/haarams'}>
                    <span>Haarams</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item  href={process.env.PUBLIC_URL+'/product/necklace'}><span>Necklace</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/vaddanam'}>
                    <span>Vaddanam</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong>
                    </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href={process.env.PUBLIC_URL+'/#custom-jewlery'}>
                  Custom Jewelry 
                </Nav.Link>
                <Nav.Link href={process.env.PUBLIC_URL+'/#faq'}>
                  FAQ
                </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    )
}
export default Menu;