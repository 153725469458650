import { Button, Col, Container, Image, Row, Modal, Form } from "react-bootstrap";
import CustomJewleryImage from '../../assets/custom-jewlery.png'
// import MakingVideo from '../../assets/making-video.gif'
import ChatIcon from '../../assets/chat@2x.png'
import CraftedIcon from '../../assets/Get it crafted.png'
import ComeLifeIcon from '../../assets/See it come to life.png'
import TrestureIcon from '../../assets/Tresure it.png'
import ArrowRight from '../../assets/ep_arrow-up-bold.png'
import JewleryProcess2 from '../../assets/CustomJewelryProcess2.png'
import JewleryProcess1 from '../../assets/Custom Document _ 7x5 in2 1.png'
import FormImage from '../../assets/cuate.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState } from "react";
import { DesignNowHandler } from "../../services/DataController";
export default function CustomJewlery() {
    const [modalShow, setModalShow] = useState(false);
    const [visibleBlock,setVisibleBlock] = useState('journey')
    const [designNow,setDesignNow] = useState(false);
    const [userData, setUserData] = useState({firstName:'',lastName:'',email:'',mobileNo:''})
    const [validated, setValidated] = useState(false);
    const [errors,setErrors]=useState(false)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            if(userData.mobileNo.length!==10){
                setErrors(true);
                event.preventDefault();
            } else {
                event.preventDefault();
                setErrors(false)
                DesignNowHandler(userData).then((res)=>{
                    if(res.data.success===1) {
                        window.location.href=`${process.env.PUBLIC_URL}/thank-you`;
                    } else {
                        alert(res.data.message);
                    }
                }).catch((err)=>{
                    alert('Something went wrong please try again');
                })
            }
        }
        setValidated(true);
    };
    const responsive = {
        0:{
                items:2.2,
                nav:true
            },
            600:{
                items:2.2,
                nav:true
            },
            1000:{
                items: 5,
                nav:true,
                loop:true
            }
    }
    return(
        <section className="custom-jewlery" id="custom-jewlery">
            <Container>
                <Row>
                    <Col md="6" lg="6" sm="6" xs="7" className="bg-section-custom">
                        <div className="custom-content-block">
                            <div className="content-position">
                                <h4>Custom Jewelry</h4>
                                <h2>Inspired by You. Crafted by Us.</h2>
                                <p className="mt-3">We Hand Craft Custom Engagement Rings, Stunning Earrings, Lavish Necklaces, and so much more!</p>
                                <div className="button mt-3">
                                    <Button className="btn btn-primary" onClick={()=>setModalShow(true)}>Learn more</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="5" className="mobile-end">
                        <div className="custom-image">
                            <Image src={CustomJewleryImage} alt="CustomJewlery" title=""CustomJewlery />
                        </div>
                    </Col>
                </Row>
            </Container>
            {/** Jewlery Dialog */}
            <Modal
                size="lg"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                   <Row>
                    <Col md="4" lg="4" sm="4" xs="12">
                        <div className="making-video">
                            <Image src={process.env.PUBLIC_URL+'/video/making-video.gif'} alt="MakingVideo" title="MakingVideo" className="w-100" />
                        </div>
                    </Col>
                    <Col md="8" lg="8" sm="8" xs="12" className="mobile-spacing">
                        {
                            visibleBlock==='journey' ?
                              <>
                                <h2 className="custom-jtitle">The Journey</h2>
                                <section className="desktop-view">
                                <Row>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <div className="custom-jewlery-icon">
                                            <Image src={ChatIcon} alt="ChatIcon" title="ChatIcon" />
                                            <h2 className="journey-title">1-Pick your design</h2>
                                            <p>Discuss design ideas, options, budget, with your jewelry consultant - your guide to the custom experience.</p>
                                        </div>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <div className="custom-jewlery-icon">
                                            <Image src={CraftedIcon} alt="CraftedIcon" title="CraftedIcon" />
                                            <h2 className="journey-title">2-Get it crafted</h2>
                                            <p>See sketches and concept art designed just for you by our jewelry artists.</p>
                                        </div>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <div className="custom-jewlery-icon">
                                            <Image src={ComeLifeIcon} alt="ComeLifeIcon" title="ComeLifeIcon" />
                                            <h2 className="journey-title">3-See it come to life</h2>
                                            <p>Visualize your piece with high resolution 3D renderings, and we'll adjust every detail until you tell us it's perfect.</p>
                                        </div>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <div className="custom-jewlery-icon">
                                            <Image src={TrestureIcon} alt="TrestureIcon" title="TrestureIcon" />
                                            <h2 className="journey-title">4-Tresure it</h2>
                                            <p>Receive your custom piece, with the assurance it's been made to wear and last a lifetime.</p>
                                        </div>
                                    </Col>
                                </Row>
                                </section>
                                <section className="mobile-view">
                                    <OwlCarousel className='owl-theme journery-carousel' margin={1} responsive={responsive} navContainer=".custom-nav">
                                        <div className='item'>
                                            <div className="custom-jewlery-icon">
                                                <Image src={ChatIcon} alt="ChatIcon" title="ChatIcon" />
                                                <h2 className="journey-title">1-Pick your design</h2>
                                                <p>Discuss design ideas, options, budget, with your jewelry consultant - your guide to the custom experience.</p>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className="custom-jewlery-icon">
                                                <Image src={CraftedIcon} alt="CraftedIcon" title="CraftedIcon" />
                                                <h2 className="journey-title">2-Get it crafted</h2>
                                                <p>See sketches and concept art designed just for you by our jewelry artists.</p>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className="custom-jewlery-icon">
                                                <Image src={ComeLifeIcon} alt="ComeLifeIcon" title="ComeLifeIcon" />
                                                <h2 className="journey-title">3-See it come to life</h2>
                                                <p>Visualize your piece with high resolution 3D renderings, and we'll adjust every detail until you tell us it's perfect.</p>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className="custom-jewlery-icon">
                                                <Image src={TrestureIcon} alt="TrestureIcon" title="TrestureIcon" />
                                                <h2 className="journey-title">4-Tresure it</h2>
                                                <p>Receive your custom piece, with the assurance it's been made to wear and last a lifetime.</p>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </section>
                                <div className="jounery-footer-block">
                                    <Row>
                                        <Col md="7" lg="7" sm="7" xs="12">
                                            <h2 className="design-footer-title">Custom Jewelry Process <Image src={ArrowRight} alt="ArrowRight" title="ArrowRight" onClick={()=>setVisibleBlock('Jewlery')} /></h2>
                                        </Col>
                                        <Col md="5" lg="5" sm="5" xs="12" className="text-end">
                                            <Button className="btn btn-primary" onClick={()=>setDesignNow(true)}>Design Now</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                            :
                            <>
                                <h2 className="custom-jtitle">Custom Jewelry Process</h2>
                                <Row>
                                    <Col md="12" lg="12" sm="12" xs="12">
                                        <div className="process-images">
                                            <Image src={JewleryProcess1} alt="JewleryProcess1" title="JewleryProcess1" className="w-100" />
                                            <Image src={JewleryProcess2} alt="JewleryProcess2" title="JewleryProcess2" className="w-100" />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="jounery-footer-block">
                                    <Row>
                                    <Col md="5" lg="5" sm="5" xs="12" className="mobile-center">
                                            <Button className="btn btn-primary" onClick={()=>setDesignNow(true)}>Design Now</Button>
                                        </Col>
                                        <Col md="7" lg="7" sm="7" xs="12" className="text-end">
                                            <h2 className="design-footer-title"><Image src={ArrowRight} alt="ArrowRight" title="ArrowRight" onClick={()=>setVisibleBlock('journey')} /> The Journey</h2>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                    </Col>
                   </Row>
                </Modal.Body>
            </Modal>
            {
                /** Design Now */
            }
            <Modal
                size="lg"
                show={designNow}
                onHide={() => setDesignNow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                className="design-form-modal"
                centered
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="border-bottom">
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="form-image">
                                <Image src={FormImage} alt="FormImage" title="FormImage" />
                            </div>
                        </Col>
                        <Col md="8" lg="8" sm="8" xs="12">
                            <div className="form-content">
                                <h3 className="section-title">We would love to gift you the joy of Customization</h3>
                                <p>Customization is our unique service where we understand your jewelry taste, design, concept and carve out the amazing piece of beauty as you had visualized.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h2 className="form-title">Drop in your details and get in touch with us!</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mt-3 form">
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="" required onChange={e=>setUserData({...userData,firstName:e.target.value})} value={userData.firstName} />
                                            <div className="text-caption">First name</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="mobile-hide"></Form.Label>
                                            <Form.Control type="text" onChange={e=>setUserData({...userData,lastName:e.target.value})} value={userData.lastName} required />
                                            <div className="text-caption">Last name</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="" onChange={e=>setUserData({...userData,email:e.target.value})} value={userData.email} required />
                                            <div className="text-caption">example@example.com</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control type="number" pattern="[0-9]*" className={errors?'form-control is-invalid' :'form-control'} placeholder="" onChange={e=>setUserData({...userData,mobileNo:e.target.validity.valid?e.target.value:userData.mobileNo})} value={userData.mobileNo} required />
                                            </Form.Group>
                                            {errors ?
                                                <div className='invisible-feedback'>Please Enter Correct Mobile Number</div>:null
                                            }
                                    </Col>
                                </Row>
                                <div className="button mt-3 text-center mb-3">
                                    <Button type="submit" className="btn btn-submit">Submit</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
        
    )
}