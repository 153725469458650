import { Col, Container, Row, Image } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Earings from '../../assets/earings.png'
import Rings from '../../assets/rings.png';
import Bangles from '../../assets/bangles-v1.png'
import Neckale from '../../assets/Neckale.png'
import Accessories from '../../assets/accessories.png'
import Hyderabad from '../../assets/hyd.png'
import Mumbai from '../../assets/mumbai.png'
import { useHistory } from "react-router-dom";
export default function Favorites() {
    const responsive = {
        0:{
                items:2.2,
                nav:true
            },
            600:{
                items:2.2,
                nav:true
            },
            1000:{
                items: 5,
                nav:true,
                loop:true
            }
    }
    const navigate = useHistory();
    const Naviagate = (url) => {
        // const history = useNavigate();
        window.location.href=`${process.env.PUBLIC_URL}${url}`
    }
    return(
        <section className="favorites" id="shop">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h2 className="section-title text-center">Pick your Favorites</h2>
                    </Col>
                </Row>
                <Row className="mt-5 favorite-space-adjust">
                    <Col md="12" lg="12" sm="12" xs="12">
                    <OwlCarousel className='owl-theme' loop margin={1} responsive={responsive} navContainer=".custom-nav">
                        <div className='item' onClick={()=>Naviagate('coming-soon')}>
                            <div class="jewlery-image">
                                <Image src={Rings} alt="Rings" title="Rings" class="w-100" />
                                <h2 class="jewlery-title">Rings</h2>
                            </div>
                        </div>
                        <div className='item' onClick={()=>Naviagate('product/earrings')}>
                            <div class="jewlery-image">
                                <Image src={Earings} alt="Earings" title="Earings" class="w-100" />
                                <h2 class="jewlery-title">EARRINGS</h2>
                            </div>
                        </div>
                        <div className='item' onClick={()=>Naviagate('product/necklace')}>
                            <div class="jewlery-image">
                                <Image src={Neckale} alt="NECKLACES" title="NECKLACES" class="w-100" />
                                <h2 class="jewlery-title">NECKLACES</h2>
                            </div>
                        </div>
                        <div className='item' onClick={()=>Naviagate('coming-soon')}>
                            <div class="jewlery-image">
                                <Image src={Bangles} alt="Bangles" title="Bangles" class="w-100" />
                                <h2 class="jewlery-title">Bangles</h2>
                            </div>
                        </div>
                        <div className='item' onClick={()=>Naviagate('coming-soon')}>
                            <div class="jewlery-image">
                                <Image src={Accessories} alt="ACCESSORIES" title="ACCESSORIES" class="w-100" />
                                <h2 class="jewlery-title">ACCESSORIES</h2>
                            </div>
                        </div>
                    </OwlCarousel>
                    </Col>
                </Row>
                <Row className="relative-position">
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h3 className="know-more-title">To explore more Designs Visit to the store</h3>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                        <div className="hyd-location">
                            <Image src={Hyderabad} alt="Hyderabad" title="Hyderabad" className="w-100" />
                        </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                        <h4 className="location-title">Jewelry @ Houston <br/>now at Indian Prices</h4>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                        <div className="hyd-location">
                            <Image src={Mumbai} alt="Mumbai" title="Mumbai" className="w-100" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}