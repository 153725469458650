import axios from "axios"

export const DesignNowHandler = (data) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.PUBLIC_URL}/apis/design-info.php`,{
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phNo: data.mobileNo
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}
export const BookNowHandler = (data) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.PUBLIC_URL}/apis/book-now.php`,{
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phNo: data.mobileNo
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}

export const ProductEnquiryHandler = (data) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.PUBLIC_URL}/apis/product-enquiry.php`,{
            name: data.name,
            pCode: data.pCode,
            email: data.email,
            phNo: data.mobileNo,
            comments: data.comments
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}