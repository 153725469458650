import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import JewleryData from "../info/JewleryData"
import { Col, Container, Image, Row } from "react-bootstrap"
import './Product.scss'
export default function Products(props) {
    const {category} = useParams()
    const data = JewleryData.filter((resp)=>resp.category===category)
    useEffect(()=>{
        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    },[])
    const navigate = useHistory();
    const navigateDetails = (id) =>{
        window.location.href=`${process.env.PUBLIC_URL}/product/${category}/${id}`;
    }
    return(
        <>
        <section className="products">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                       <h4 className="section-title text-captilize text-center">{category}</h4>
                    </Col>
                </Row>
                <Row className="mt-3">
                    {
                         data && data.length>0 ?
                         data.map(response => {
                            return(
                                <Col md="4" lg="4" sm="4" xs="12" className="cursor-pointer" onClick={()=>navigateDetails(response.id)}>
                                    <div className="product-block">
                                        <div className="product-image">
                                            <Image src={process.env.PUBLIC_URL+'/'+response.coverImage} />
                                        </div>
                                        <h2 className="product-title">{response.title}</h2>
                                    </div>
                                </Col>
                            )
                         })
                         : null
                    }
                </Row>
            </Container>
        </section>
        </>
    )
}