import Banner from "../components/banner/Banner";
// import Footer from "../components/footer/Footer";
// import Header from "../components/header/Header";
import AboutJewleries from "../components/homepage/AboutJewleries";
import CertifiedBlock from "../components/homepage/CertifiedBlock";
import CustomJewlery from "../components/homepage/CustomJewlery";
import FAQ from "../components/homepage/FAQ";
import Favorites from "../components/homepage/Favorites";
import HeritageJewlery from "../components/homepage/HeritageJewlery";
import Testimonials from "../components/homepage/Testimonials";
// import Menu from "../components/menu/Menu";

export default function HomePage() {
    return(
        <>
        <Banner />
        <CertifiedBlock />
        <CustomJewlery />
        <Favorites />
        <HeritageJewlery />
        <Testimonials />
        <FAQ />
        <AboutJewleries />
        </>
    )
}