import { Button, Col, Container, Form, Image, Row, Modal } from "react-bootstrap";
import NeckleO from '../../assets/necklace-0.png'
import Jelwery1 from '../../assets/25-1@2x.png'
import Jelwery2 from '../../assets/32-1@2x.png'
import Jelwery3 from '../../assets/6-11@2x.png'
import FormImage from '../../assets/cuate.svg'
import WhatsappIcon from '../../assets/whatsapp.png'
import { useState } from "react";
import { BookNowHandler } from "../../services/DataController";
export default function HeritageJewlery() {
    const [userData, setUserData] = useState({firstName:'',lastName:'',email:'',mobileNo:''})
    const [validated, setValidated] = useState(false);
    const [bookNow,setBookNow] = useState(false);
    const [errors,setErrors]=useState(false)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            if(userData.mobileNo.length!==10){
                setErrors(true);
                event.preventDefault();
            } else {
                event.preventDefault();
                setErrors(false)
                BookNowHandler(userData).then((res)=>{
                    if(res.data.success===1) {
                        window.location.href=`${process.env.PUBLIC_URL}/thank-you`;
                    } else {
                        alert(res.data.message);
                    }
                }).catch((err)=>{
                    alert('Something went wrong please try again');
                })
            }
        }
        setValidated(true);
    };
    return(
        <section className="heritage-jewlery">
            <Container>
                <Row>
                    <Col md="7" lg="7" sm="7" xs="12" className="padding-right-none">
                        <div className="heritage-jewlery-image">
                            <Image src={NeckleO} alt="necklace-0" title="necklace-0" className="w-100" />
                        </div>
                    </Col>
                    <Col md="5" lg="5" sm="5" xs="12" className="bg-jewlery-content">
                        <div className="jewlery-content">
                            <h2 className="section-title">Heritage Jewelry</h2>
                            <h6>Collection <span></span></h6>
                            <p className="mt-3">Timeless Treasures to Pass Down for Generations</p>
                            <Row>
                                <Col md="3" lg="3" sm="3" xs="4" className="mobile-w-50">
                                    <div className="jewlery-image1">
                                        <Image src={Jelwery1} alt="Jewlery-1" title="Jewlery-1" class="w-100" />
                                    </div>
                                </Col>
                                <Col md="3" lg="3" sm="3" xs="4" className="mobile-w-50">
                                    <div className="jewlery-image1">
                                        <Image src={Jelwery2} alt="Jewlery-2" title="Jewlery-2" class="w-100" />
                                    </div>
                                </Col>
                                <Col md="3" lg="3" sm="3" xs="4" className="mobile-w-50">
                                    <div className="jewlery-image1">
                                        <Image src={Jelwery3} alt="Jewlery-3" title="Jewlery-3" class="w-100" />
                                    </div>
                                </Col>
                            </Row>
                            <div className="button mt-5">
                                <button className="btn btn-primary" onClick={()=>setBookNow(true)}>Book an appointment</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                /** Design Now */
            }
            <Modal
                size="lg"
                show={bookNow}
                onHide={() => setBookNow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                className="design-form-modal"
                centered
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className="border-bottom">
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="form-image">
                                <Image src={FormImage} alt="FormImage" title="FormImage" />
                            </div>
                        </Col>
                        <Col md="8" lg="8" sm="8" xs="12">
                            <div className="form-content">
                                <h2 className="section-title">Book an appointment</h2>
                                <p>Visit our store to find a plethora of sparkling stunning jewelry pieces and pick your favourites by just booking an appointment with us! See you there!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="12" lg="12" sm="12" xs="12">
                            <h2 className="form-title">Book a one-on-one/ walk-in consultation with our jewellery specialists</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mt-3 form">
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Name <sup>*</sup></Form.Label>
                                            <Form.Control type="text" placeholder="" required onChange={e=>setUserData({...userData,firstName:e.target.value})} value={userData.firstName} />
                                            <div className="text-caption">First name</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="mobile-hide"></Form.Label>
                                            <Form.Control type="text" onChange={e=>setUserData({...userData,lastName:e.target.value})} value={userData.lastName} required />
                                            <div className="text-caption">Last name</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email <sup>*</sup></Form.Label>
                                            <Form.Control type="email" placeholder="" onChange={e=>setUserData({...userData,email:e.target.value})} value={userData.email} required />
                                            <div className="text-caption">example@example.com</div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Mobile Number <sup>*</sup></Form.Label>
                                            <Form.Control type="number" pattern="[0-9]*" className={errors?'form-control is-invalid' :'form-control'} placeholder="" onChange={e=>setUserData({...userData,mobileNo:e.target.validity.valid?e.target.value:userData.mobileNo})} value={userData.mobileNo} required />
                                            </Form.Group>
                                            {errors ?
                                                <div className='invisible-feedback'>Please Enter Correct Mobile Number</div>:null
                                            }
                                    </Col>
                                </Row>
                                <div className="button mt-3 text-center mb-3">
                                    <Button type="submit" className="btn btn-submit">Submit</Button>
                                </div>
                            </Form>
                            <div className="mt-3 book-appointment-icons text-center">
                                <p>Or you can reach out to us on <strong>Whatsapp</strong> too.</p>
                                <div className="whatsapp-book-icon">
                                    <Image src={WhatsappIcon} alt="WhatsappIcon" title="WhatsappIcon" />
                                    <span><a href="http://wa.me/18328460004?text=I%27m%20Looking%20for%20gold%20jewlery" target="_blank" rel="noreferrer">http://wa.me/18328460004?text=I%27m%20Looking%20for%20gold%20jewlery</a></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    )
}