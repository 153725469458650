import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import JewleryData from "../info/JewleryData"
import { Button, Col, Container, Form, Image, Row, Table } from "react-bootstrap"
import MoneyG from '../assets/money-garuentee.svg'
import Exchange from '../assets/exchange.svg'
import Certified from '../assets/certified.svg'
import BILogo from '../assets/BSI logo.png'
import GLogo from '../assets/GIA logo.png'
import './Product.scss'
import { ProductEnquiryHandler } from "../services/DataController"
export default function ProductDetails(props) {
    const {id} = useParams()
    const data = JewleryData.filter((resp)=>resp.id===parseInt(id))
    const [enquiryData,setEnquiryData] = useState({name:'',email:'',mobileNo:'',comments:'',pCode:''})
    useEffect(()=>{
        if(data && data.length>0) {
            setEnquiryData({
                ...enquiryData,
                pCode: data[0].code
            })
        }
    },[data, enquiryData,id])
    const [validated, setValidated] = useState(false);
    const [errors,setErrors]=useState(false)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            if(enquiryData.mobileNo.length!==10){
                setErrors(true);
                event.preventDefault();
            } else {
                event.preventDefault();
                setErrors(false)
                ProductEnquiryHandler(enquiryData).then((res)=>{
                    if(res.data.success===1) {
                        window.location.href=`${process.env.PUBLIC_URL}/thank-you`;
                    } else {
                        alert(res.data.message);
                    }
                }).catch((err)=>{
                    alert('Something went wrong please try again');
                })
                console.log(enquiryData)
            }
        }
        setValidated(true);
    };
    return(
        <section className="products">
            <Container>
                {
                    data && data.length>0 ?
                    data.map((response)=>{
                        return(
                            <Row>
                                <Col md="6" lg="6" sm="6" xs="12">
                                   <div className="product-detail-image">
                                       <Image src={process.env.PUBLIC_URL+'/'+response.coverImage} alt={response.title} title={response.title} className="w-100" />
                                   </div>
                                   <div className="details-logos">
                                        <Image src={BILogo} alt="BILogo" title="BILogo" />
                                        <Image src={GLogo} alt="GLogo" title="GLogo" />
                                   </div>
                                   {
                                    /** Mobile Details Display */
                                   }
                                   <div className="mobile-details-display mt-3">
                                        <h4 className="section-title text-uppercase">{response.title}</h4>
                                        <p>{response.description}</p>
                                        <Row className="mt-3">
                                            <Col md="3" lg="3" sm="3" xs="4">
                                                <div className="p-dicon">
                                                    <Image src={MoneyG} alt="Money" title="Money" />
                                                </div>
                                            </Col>
                                            <Col md="3" lg="3" sm="3" xs="4">
                                                <div className="p-dicon">
                                                    <Image src={Exchange} alt="Exchange" title="Exchange" />
                                                </div>
                                            </Col>
                                            <Col md="3" lg="3" sm="3" xs="4">
                                                <div className="p-dicon">
                                                    <Image src={Certified} alt="Certified" title="Certified" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                   <div className="product-info">
                                   <Table bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" className="product-details-title">PRODUCT INFORMATION</td>
                                                </tr>
                                                <tr>
                                                    <td>PRODUCT CODE</td>
                                                    <td>{response.code}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="product-details-title">METAL INFORMATION</td>
                                                </tr>
                                                <tr>
                                                    <td>Purity</td>
                                                    <td>22K</td>
                                                </tr>
                                                <tr>
                                                    <td>Color</td>
                                                    <td>Red and Yellow</td>
                                                </tr>
                                                <tr>
                                                    <td>Gross Weight</td>
                                                    <td>15 gms</td>
                                                </tr>
                                                <tr>
                                                    <td>Net Weight</td>
                                                    <td>10-12GRMS</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="product-details-title">STONE INFORMATION</td>
                                                </tr>
                                                <tr>
                                                    <td>Diamond Carats</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="product-details-title"></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="product-details-title"></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col md="6" lg="6" sm="6" xs="12">
                                    <div className="product-details-content">
                                        {
                                            /** Desktop Details Display */
                                        }
                                        <div className="desktop-details-display">
                                            <h4 className="section-title text-uppercase">{response.title}</h4>
                                            <p>{response.description}</p>
                                            <Row className="mt-3">
                                                <Col md="3" lg="3" sm="3" xs="4">
                                                    <div className="p-dicon">
                                                        <Image src={MoneyG} alt="Money" title="Money" />
                                                    </div>
                                                </Col>
                                                <Col md="3" lg="3" sm="3" xs="4">
                                                    <div className="p-dicon">
                                                        <Image src={Exchange} alt="Exchange" title="Exchange" />
                                                    </div>
                                                </Col>
                                                <Col md="3" lg="3" sm="3" xs="4">
                                                    <div className="p-dicon">
                                                        <Image src={Certified} alt="Certified" title="Certified" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="enquiry-form">
                                            <h4 className="enquiry-title text-center">Enquiry</h4>
                                            <Form validated={validated} onSubmit={handleSubmit}>
                                                <Form.Control placeholder="Name" className="mb-3" required onChange={e=>setEnquiryData({...enquiryData,name:e.target.value})} value={enquiryData.name} />
                                                <Form.Control placeholder="Email" className="mb-3" required onChange={e=>setEnquiryData({...enquiryData,email:e.target.value})} value={enquiryData.email} />
                                                <Form.Control className={errors?'form-control is-invalid mb-3' :'form-control mb-3'} placeholder="Number" required onChange={e=>setEnquiryData({...enquiryData,mobileNo:e.target.value})} value={enquiryData.mobileNo} />
                                                {errors ?
                                                <div className='invisible-feedback'>Please Enter Correct Mobile Number</div>:null
                                            }
                                                <Form.Control placeholder="Any Question?" className="mb-3"onChange={e=>setEnquiryData({...enquiryData,comments:e.target.value})} value={enquiryData.comments} />
                                                <div className="buttons mt-5 text-center">
                                                    <Button type="submit" className="btn btn-primary">Submit</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                    :null
                }
            </Container>
        </section>
    )
}