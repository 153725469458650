import { Col, Container, Image, Row } from "react-bootstrap";
import TrustedJewlery from '../../assets/trusted-jeweller.svg'
import CertifiedDiamonds from '../../assets/gia-certified-diamonds.svg'
import BiCertfiedDiamonds from '../../assets/bis-certified-gold.svg'
import FreeShipping from '../../assets/free-shipping.svg'
import EasyReturns from '../../assets/easy-returns.svg'
import './Homepage.scss'
export default function CertifiedBlock() {
    return(
        <section className="certified-block">
            <Container>
                <Row>
                    <Col md="8" lg="8" sm="8" xs="8" className="self-center">
                        <div className="certified-logos">
                            <Image src={TrustedJewlery} alt="TrustedJewlery" title="TrustedJewlery" />
                            <Image src={CertifiedDiamonds} alt="CertifiedDiamonds" title="CertifiedDiamonds" />
                            <Image src={BiCertfiedDiamonds} alt="BiCertfiedDiamonds" title="BiCertfiedDiamonds" />
                         </div>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="4" className="self-center text-end">
                        <div className="certified-logos">
                            <Image src={FreeShipping} alt="FreeShipping" title="FreeShipping" />
                            <Image src={EasyReturns} alt="EasyReturns" title="EasyReturns" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}