import { Col, Container, Accordion, Row } from "react-bootstrap";
export default function FAQ() {
    return(
        <section className="faq" id="faq">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h2 className="section-title text-center">Frequently Asked Questions</h2>
                        <Accordion defaultActiveKey="0" className="mt-5">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How long does a project take?</Accordion.Header>
                                <Accordion.Body>
                                  If it is a custom order piece, it depends on the size and the intricacy of the work related to it. A small choker would take around 2 weeks to be delivered byt a big Haaram would take like 3-4 weeks to be delivered to the customer. Whatever you have in your mind can be turned into reality.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>How much will my piece cost?</Accordion.Header>
                                <Accordion.Body>
                                  It depends on the jewelery piece selected and what quality of diamonds or how much gold is going to be used in making the piece. We believe in complete transparency and would provide the full breakup with all details of the diamonds, uncut, or color stones.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>When do i get to see a design? Do I have to pay first?</Accordion.Header>
                                <Accordion.Body className="accordion-button-last">
                                  You can see the drawing in 2 working days and once that is approved in another 2 days, you would get to see a CAD design of the selected piece. Post that, we will share you the photos at every step of the process. You would have to pay 30% first and later we can take payments in installments too.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}