import { Col, Container, Image, Row, Carousel } from "react-bootstrap";
import A from '../../assets/a.png'
import B from '../../assets/b.png'
import LineShap from '../../assets/line-shape.png'
export default function Testimonials() {
    return(
        <section className="testimonials">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <div className="testimonials-title-block">
                            <Image src={A} alt="a" title="a" className="pr-sm" />
                            <h2 className="section-title">Our Sparkling Happy Customers</h2>
                            <Image src={B} alt="b" title="b" className="pl-sm" />
                        </div>
                        <div className="mt-3 line-shap text-center">
                           <Image src={LineShap} alt="LineShap" title="LineShap" />
                        </div>
                    </Col>
                </Row>
                <Carousel fade className="mt-5">
                    <Carousel.Item>
                       <p>I could not be more happy with my fiance's ring from Jianna! They took the time to educate me thoroughly on the diamond selection process, sourced a stone that was beyond any quality that I expected to get for my budget, and worked with me through every detail of the ring - a truly bespoke experience but with competitive pricing.</p>
                       <p className="mt-3">Start to finish, the whole process took less than three weeks and the ring was securely shipped right to my door. I was hesitant to go through this process without ever seeing the stone in person but, as soon as I opened the box, I was absolutely floored by the perfection and attention to detail. For an investment that requires so much trust and experience, I cannot recommend Jianna enough!</p>
                       <h2 className="testimonial-title">Moshe Forman</h2>
                    </Carousel.Item>
                    <Carousel.Item>
                       <p>I would like to say how impressed I am from the customer support side. They put the personal touch into your business and it means a lot to me as a customer. I am so happy with the product I purchased. Besides having a lot of fresh designs, they can also personalize your order and deliver within a small timeframe. Very knowledgeable in terms of their products. </p>
                       <h2 className="testimonial-title">Vidhi Thakor</h2>
                    </Carousel.Item>
                    <Carousel.Item>
                       <p>I heard about Jianna from a family friend and I went to check out their store in Kirby. They have amazing collections and have an option for customizing jewelry too ! My mom loves their jewelry & hospitality towards their customers.Great place definitely worth it ! </p>
                       <h2 className="testimonial-title">Sadia Raisa</h2>
                    </Carousel.Item>
                    <Carousel.Item>
                       <p>I reached out to have an engagement ring made while I was away on a deployment. While initially skeptical at the thought of someone making a ring for me while I was in another country, Vraj's constant communication to determine exactly how I wanted my ring to look really set me at ease. When I finally returned home and was able to visit Jianna Jewelers, my ring was nothing short of perfect.</p>
                       <p>After the help I received in perfecting that initial ring, I knew that I wouldn't want to have my jewelry made anywhere else</p>
                       <h2 className="testimonial-title">C. Fiel</h2>
                    </Carousel.Item>
                    <Carousel.Item>
                       <p>I can't recommend the mom-son duo enough. Vraj at Jianna is super humble and honest. You can't help but love them, their collection, designs and truthfulness. Thanks sridhar garu saw this post and went in to their store. Glad | did. | got my solitaires from them after researching for over a year of where to buy and the price ranges. HIGHLY recommend to just go by and see what they've to offer even if you don't need to buy anything.</p>
                       <h2 className="testimonial-title">Priya Davuluri</h2>
                    </Carousel.Item>
                    <Carousel.Item>
                       <p>There are not enough words to describe the customer service, communication, design equality and pricing. The end results of my custom ring wedding set was phenomenal. | had many pieces of my family’s heirlooms and took them in havin no experience with the process or outcome, they made it effortless and exceeded my expectations. With many diamond left over, | will be back to create another masterpiece with this business.</p>
                       <h2 className="testimonial-title">Brittany Tryon</h2>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </section>
    )
}