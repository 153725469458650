import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import Facebook from '../../assets/facebook.svg'
import Instagram from '../../assets/instagram.svg'
import Twitter from '../../assets/twitter.svg'
import Linkedin from '../../assets/linkedined.svg'
import './Footer.scss';
function Footer() {
    return(
        <>
        <section className="footer-space">
            <Container>
                <section className="desktop-footer">
                <Row className="footer desktop-footer">
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div class="footer-content text-center">
                                <h5 class="footer-title">Shop</h5>
                                <ul>
                                    <li><a href="https://jiannajewelers.com/diamond-engagement-rings-houston" target="_blank" rel="noreferrer">Engagement</a></li>
                                    <li><a href="https://jiannajewelers.com/bracelet-online-houston" target="_blank" rel="noreferrer">Bracelets</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-pendant-houston" target="_blank" rel="noreferrer">Pendants</a></li>
                                    <li><a href="https://jiannajewelers.com/womens-wedding-rings-houston" target="_blank" rel="noreferrer">Ladies Wedding Rings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-rings-online-houston" target="_blank" rel="noreferrer">Diamond Rings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-earrings-houston" target="_blank" rel="noreferrer">Earrings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-necklaces-houston" target="_blank" rel="noreferrer">Necklaces</a></li>
                                    <li><a href="https://jiannajewelers.com/?_a=collections&amp;coll_id=0" target="_blank" rel="noreferrer">All Collections</a></li>
                                </ul>
                            </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div class="footer-content text-center">
                            <h5 class="footer-title">About Jianna</h5>
                            <ul>
                                <li><a href="https://jiannajewelers.com/about-us" target="_blank" rel="noreferrer">About Jianna</a></li>
                                <li><a href="https://jiannajewelers.com/contact-us.html" target="_blank" rel="noreferrer">Our Location</a></li>
                                <li><a href="https://jiannajewelers.com/index.php?_a=bookappointment" target="_blank" rel="noreferrer">Book an Appointment</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div class="footer-content text-center">
                            <h5 class="footer-title">Info</h5>
                            <ul>
                                <li><a href="https://jiannajewelers.com/learn-about-diamond-diamond-education-jianna-jewelers.html" target="_blank" rel="noreferrer">Diamond Education</a></li>
                                <li><a href="https://jiannajewelers.com/index.php?_a=blog" target="_blank" rel="noreferrer">Jianna's Blog</a></li>
                                <li><a href="https://jiannajewelers.com/engagement-ring-buying-guide" target="_blank" rel="noreferrer">Engagement Ring Buying Guide</a></li>
                                <li><a href="https://jiannajewelers.com/how-to-take-care-of-jewelry" target="_blank" rel="noreferrer">How to Take Care of Jewelry</a></li>
                                <li><a href="https://jiannajewelers.com/privacy-policy-jianna-jewelers" target="_blank" rel="noreferrer">Privacy policy</a></li>
                                <li><a href="https://jiannajewelers.com/terms-and-conditions-jianna-jewelers" target="_blank" rel="noreferrer">Terms</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div class="footer-content text-center">
                            <h5 class="footer-title">Our Store</h5>
                            <h6>Jianna Jewelers</h6>
                            <p>3300 Kirby Drive, Unit 5A <br />
                                Houston, TX 77098</p>
                            <p><i class="fa fa-phone"></i> 713-524-3333</p>
                            <h6>Hours of Operation</h6>
                            <p>Mon-Sat: 11am - 6:00pm <br />
                                Sun: Closed</p>
                        </div>
                    </Col>
                </Row>
                </section>
                <section className="mobile-footer footer">
                    <Accordion defaultActiveKey="5">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Shop</Accordion.Header>
                            <Accordion.Body className="footer-content">
                            <ul>
                                    <li><a href="https://jiannajewelers.com/diamond-engagement-rings-houston" target="_blank" rel="noreferrer">Engagement</a></li>
                                    <li><a href="https://jiannajewelers.com/bracelet-online-houston" target="_blank" rel="noreferrer">Bracelets</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-pendant-houston" target="_blank" rel="noreferrer">Pendants</a></li>
                                    <li><a href="https://jiannajewelers.com/womens-wedding-rings-houston" target="_blank" rel="noreferrer">Ladies Wedding Rings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-rings-online-houston" target="_blank" rel="noreferrer">Diamond Rings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-earrings-houston" target="_blank" rel="noreferrer">Earrings</a></li>
                                    <li><a href="https://jiannajewelers.com/diamond-necklaces-houston" target="_blank" rel="noreferrer">Necklaces</a></li>
                                    <li><a href="https://jiannajewelers.com/?_a=collections&amp;coll_id=0" target="_blank" rel="noreferrer">All Collections</a></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>About Jianna</Accordion.Header>
                            <Accordion.Body className="footer-content">
                            <ul>
                                <li><a href="https://jiannajewelers.com/about-us" target="_blank" rel="noreferrer">About Jianna</a></li>
                                <li><a href="https://jiannajewelers.com/contact-us.html" target="_blank" rel="noreferrer">Our Location</a></li>
                                <li><a href="https://jiannajewelers.com/index.php?_a=bookappointment" target="_blank" rel="noreferrer">Book an Appointment</a></li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Info</Accordion.Header>
                            <Accordion.Body className="footer-content">
                            <ul>
                                <li><a href="https://jiannajewelers.com/learn-about-diamond-diamond-education-jianna-jewelers.html" target="_blank" rel="noreferrer">Diamond Education</a></li>
                                <li><a href="https://jiannajewelers.com/index.php?_a=blog" target="_blank" rel="noreferrer">Jianna's Blog</a></li>
                                <li><a href="https://jiannajewelers.com/engagement-ring-buying-guide" target="_blank" rel="noreferrer">Engagement Ring Buying Guide</a></li>
                                <li><a href="https://jiannajewelers.com/how-to-take-care-of-jewelry" target="_blank" rel="noreferrer">How to Take Care of Jewelry</a></li>
                                <li><a href="https://jiannajewelers.com/privacy-policy-jianna-jewelers" target="_blank" rel="noreferrer">Privacy policy</a></li>
                                <li><a href="https://jiannajewelers.com/terms-and-conditions-jianna-jewelers" target="_blank" rel="noreferrer">Terms</a></li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="last-accordian">
                            <Accordion.Header>Our Store</Accordion.Header>
                            <Accordion.Body className="footer-content">
                            <div class="footer-content text-left">
                            <h6>Jianna Jewelers</h6>
                            <p>3300 Kirby Drive, Unit 5A <br />
                                Houston, TX 77098</p>
                            <p><i class="fa fa-phone"></i> 713-524-3333</p>
                            <h6>Hours of Operation</h6>
                            <p>Mon-Sat: 11am - 6:00pm <br />
                                Sun: Closed</p>
                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </section>
                <section class="copyright">
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <ul class="icons-display">
                                <li><a href="https://www.facebook.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Facebook} /></a></li>
                                <li><a href="https://www.instagram.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Instagram} /></a></li>
                                <li><a href="https://twitter.com/JiannaJewelers" target="_blank" rel="noreferrer"><Image src={Twitter} /></a></li>
                                <li><a href="https://www.linkedin.com/company/jianna-jewelers/posts/?feedView=all" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a></li>
                            </ul>
                            <p class="mt-3 text-center">© 2021 Jianna Jewelers All Rights Reserved</p>
                        </Col>
                    </Row>
                </section> 
            </Container>
        </section>
        </>
    )
}
export default Footer;