import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Banner1 from '../../assets/hero sec2.jpg'
import Banner2 from '../../assets/hero sec1.jpg'
import Banner3 from '../../assets/hero sec3.jpg'
import { Button, Image } from 'react-bootstrap';
import './Banner.scss'
import Arrow from '../../assets/arrow.png'
function Banner() {
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:true
            },
            1000:{
                autoWidth:true,
                items: 3,
                nav:true,
                loop:true
            }
    }
    let sliderRef=null;
    const click = ()=>{ console.log(sliderRef); sliderRef.next(500); }
    return(
        <section className='banner-slider'>
            <div className='carousel'>
                <OwlCarousel className='owl-theme' loop margin={15} responsive={responsive} ref={slider => (sliderRef = slider)} navContainer=".custom-nav">
                    <div className='item'>
                        <div className='banner-block'>
                            <Image src={Banner1} alt="Banner 1" title="Banner 1" />
                        </div>
                    </div>
                    <div className='item'>
                        <div className='banner-block'>
                            <Image src={Banner2} alt="Banner 2" title="Banner 2" />
                        </div>
                    </div>
                    <div className='item'>
                        <div className='banner-block'>
                            <Image src={Banner3} alt="Banner 3" title="Banner 3" />
                        </div>
                    </div>
                </OwlCarousel>
                <div className='custom-nav'>
                    <div className='custom-scroll-button'>
                        <Button className='btn btn-transparent' onClick={()=>click()}><Image src={Arrow} /></Button>
                    </div>
                </div>
                <div className='banner-block-content'>
                    <div>
                        <h4>Enter our Enchanting world of</h4>
                        <h1>Heritage <br/>Jewelry</h1>
                        <Button className='btn btn-primary mt-3' onClick={()=>window.location.href=`${process.env.PUBLIC_URL}/#shop`}>See collection</Button>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Banner;