import { Col, Container, Row,Tabs,Tab, Image } from "react-bootstrap";
import JianaJelweryImage from '../../assets/About.png'
export default function AboutJewleries() {
    return(
        <section className="about-jewleries">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                        <Tab eventKey="home" title="Jianna Jewelers">
                            <div className="tab-content">
                                    <Row>
                                    <Col md="5" lg="5" sm="5" xs="12">
                                        <div className="tab-image">
                                            <Image src={JianaJelweryImage} alt="JianaJelweryImage" title="JianaJelweryImage" className="w-100" />
                                        </div>
                                    </Col>
                                    <Col md="7" lg="7" sm="7" xs="12">
                                        <div className="tab-content-block">
                                            <h3 className="section-title mb-3">About Jianna Jewelers</h3>
                                            <p>At the core of our company values is our devotion to our customers. We view each client as an integral part of our family and it is evident in the exceptional standard of our products. Our skilled artisans are renowned in the industry for their exceptional craftsmanship. We maintain absolute transparency from the sourcing of stones to the pricing of our jewelry. Our distinguished diamonds are natural and conflict-free, bolstering the credibility of our merchandise. By obtaining them directly from mines, we are able to create exquisite Jianna jewelry and present them to you at the most affordable price points.</p>
                                            <p>Our inculcated sense of commitment and duty to our clients and the wider community motivates us to make high-quality jewelry accessible to everyone. Looking ahead, we vow to remain steadfast in our pledge to provide exceptional service and hope to have your enduring support for years to come.</p>
                                            <p>It is our responsibility to offer high-quality jewelry at affordable prices for everyone. Moving ahead, we will remain dedicated to providing exceptional service and look forward to earning your continued support in the future.</p>
                                        </div>
                                    </Col>
                                    </Row>
                                </div>
                        </Tab>
                        <Tab eventKey="profile" title="Jianna Heritage">
                            <div className="tab-content">
                                <Row>
                                    <Col md="5" lg="5" sm="5" xs="12">
                                        <div className="tab-image">
                                            <Image src={JianaJelweryImage} alt="JianaJelweryImage" title="JianaJelweryImage" className="w-100" />
                                        </div>
                                    </Col>
                                    <Col md="7" lg="7" sm="7" xs="12">
                                        <div className="tab-content-block">
                                            <h3 className="section-title mb-3">About Jianna Heritage</h3>
                                            <p>Jianna's legacy as a premier jeweler spans three generations and reaches across three continents with five stores. Our loyal clientele has endorsed and entrusted us for more than thirty years, and we are excited to embark on our next chapter of international growth with Jianna Heritage that reflects our legacy and taking you on an exquisitely antique yet contemporary route of Heritage. Jianna Heritage narrates impeccable tales of our rich and vivid tradition and culture with great intricacy. We are enthralled to bring to you the Royal touch of Heritage with the dazzle of diamonds as unique mix of richness.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                    </Tabs>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}