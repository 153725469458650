const JewleryData = [
    {
        id: 1,
        title: 'ruby and emerald studs',
        description: 'These beautiful studs depict the gods from Trethayuga is finely crafted with diamonds and rubies',
        code: '2D6A7896',
        grossWeight: '14 gms',
        netWeight: '10-12GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7896.jpg'
    },
    {
        id: 2,
        title: 'sri sita rama mudrika studs',
        description: 'These beautiful studs depict the gods from Trethayuga is finely crafted with diamonds and rubies',
        code: '2D6A7896',
        grossWeight: '14 gms',
        netWeight: '10-12GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7896.jpg'
    },
    {
        id: 3,
        title: 'divine jhumkas',
        description: "The famous and gorgeous jhumkas are every girl's best friend. these fine artisanal tops will go on any traditional indian attire. amping up your divinity and grace.",
        code: '2D6A7906',
        grossWeight: '30 gms',
        netWeight: '30GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7906.jpg'
    },
    {
        id: 4,
        title: 'emerald drop hangings',
        description: 'These beautiful emerald drop hangings will amp up your look with royal touch',
        code: '2D6A7913',
        grossWeight: '30 gms',
        netWeight: '25GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7913.jpg'
    },
    {
        id: 5,
        title: 'suave ruby and emerald hangings',
        description: 'Beautiful ruby and emerald hangings inspired from the architecture and geometric patterns of south indian temples.',
        code: '2D6A7917',
        grossWeight: '30 gms',
        netWeight: '25GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7917.jpg'
    },
    {
        id: 6,
        title: 'pearl drop jhumkas',
        description: 'Look the goddess you are. these opulent pearl drop jhumka set is surely going to make you swoon.',
        code: '2D6A7922',
        grossWeight: '32 gms',
        netWeight: '26-28GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7922.jpg'
    },
    {
        id: 7,
        title: "the queen's studs",
        description: 'These elegant gem stone studded tops were said to be worn by the queens of the ancient india. they are particularly famous in south india. these are surprisingly very eloquent on both traditional and western attires.',
        code: '2D6A7923',
        grossWeight: '15 gms',
        netWeight: '10-12GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'earrings',
        coverImage: 'products/2D6A7923.jpg'
    },
    {
        id: 8,
        title: "lakshmi kaasula haaram",
        description: 'Intricately crafted traditional haram depicting the royal Indian Culture and traditions narrating the tales of wealth and prosperity with elephants carved on the either side of goddess Laxmi and studded with beautiful stones and drops of pearls',
        code: '2D6A3888',
        grossWeight: '70 gms',
        netWeight: '55-60GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'haarams',
        coverImage: 'products/2D6A3888.jpg'
    },
    {
        id: 9,
        title: "sri sita rama mudrika haaram",
        description: 'Intricately designed depicting the thretha yugas sri rama, lakshmana and sita. studded with beautiful emeralds and rubies.',
        code: '2D6A3901',
        grossWeight: '70-75 gms',
        netWeight: '55-60GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'haarams',
        coverImage: 'products/2D6A3901.jpg'
    },
    {
        id: 11,
        title: "stunning emerald haaram",
        description: 'They say emeralds bring peace, prosperity and harmony. this designer haaram is show stealer, surely making heads turn your way.',
        code: '2D6A7609',
        grossWeight: '70-75 gms',
        netWeight: '55-60GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'haarams',
        coverImage: 'products/2D6A7609.jpg'
    },
    {
        id: 12,
        title: "the raanulavari haaram",
        description: 'Simply said ranulavari vari haaram is necklace worn by queens. this serene necklace studded with pearls diamonds and blue gemstones will add to your splendid look',
        code: '2D6A7613',
        grossWeight: '70 gms',
        netWeight: '50-55GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'haarams',
        coverImage: 'products/2D6A7613.jpg'
    },
    {
        id: 13,
        title: "mango haaram",
        description: 'This beautiful haaram is extremely famous in south india. beautifully designed by best designers this haaram is studded wih rubies, pearls and emeralds with depiction of goddess lakshmi in the centre.',
        code: '2D6A3897',
        grossWeight: '85 gms',
        netWeight: '70GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'haarams',
        coverImage: 'products/2D6A3897.jpg'
    },
    {
        id: 14,
        title: "pearl magic",
        description: 'Straight out of a godly tale where you can imagine the beautiful apsaras wearing these stunning pear drop necklace with finely crafted lotus motifs all around',
        code: '2D6A7657',
        grossWeight: '240 gms',
        netWeight: '221.26GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'necklace',
        coverImage: 'products/2D6A7657.jpg'
    },
    {
        id: 15,
        title: "gorgeous diamond choker",
        description: 'Right out of the ancient depictions of the devkanyas. this stunning choker necklace will leave you feeling like an apsara. beautifully crafted with gold and diamonds.',
        code: '2D6A7653',
        grossWeight: '55 gms',
        netWeight: '47GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'necklace',
        coverImage: 'products/2D6A7653.jpg'
    },
    {
        id: 16,
        title: "lakshmi vaddanam",
        description: 'Waist belt also known as Vaddanam in South India is a royal piece of elegance donned with Traditional sarees and Langa Vani to amp up the grace meter.',
        code: '2D6A3877',
        grossWeight: '170 gms',
        netWeight: '160GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'vaddanam',
        coverImage: 'products/2D6A3877.jpg'
    },
    {
        id: 17,
        title: "rathnala vaddanam",
        description: 'This elegant waistbelt studded with diamonds , emeralds and rubies is finely crafted with depictions of goddess lakshmi, lotus flowers and elephants which represent the power and prosperous of the feminine',
        code: '2D6A3878',
        grossWeight: '185 gms',
        netWeight: '160GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        category: 'vaddanam',
        coverImage: 'products/2D6A3878.jpg'
    },
    {
        id: 18,
        title: "kamala rathna vaddanam",
        description: 'Kamala is another name of goddess lakshmi, she is the goddess of wealth and fortune. this artisanal piece is made with rubies, emerald and diamonds.',
        category: 'vaddanam',
        ode: '2D6A3881',
        grossWeight: '175 gms',
        netWeight: '160GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        coverImage: 'products/2D6A3881.jpg'
    },
    {
        id: 19,
        title: "dasavathara vaddanam",
        description: 'This authentic waistbelt is intricately crafted with depictions of dasavatharas of lord narayana and lord narayana on his seshanaga along with his consort lakshmi devi.',
        category: 'vaddanam',
        ode: '2D6A3883',
        grossWeight: '165 gms',
        netWeight: '160GRMS',
        color:'Red and Yellow',
        purity: '22K',
        stoneInfo: 'Diamond Carats',
        coverImage: 'products/2D6A3883.jpg'
    }
]
export default JewleryData;