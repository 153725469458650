import { Nav, Container, Navbar, Row, Col, Image, Offcanvas, NavDropdown } from "react-bootstrap";
import '../header/Header.scss';
import PhoneCall from '../../assets/phone-call.png'
import './Menu.scss';
import Logo from '../../assets/logo.png'
import WhatsappIcon from '../../assets/whatsapp.png'
import { useState } from "react";
import RightIcon from '../../assets/right-arrow.png'
import Facebook from '../../assets/facebook-colored.svg'
import Instagram from '../../assets/insta-colored.svg'
import Twitter from '../../assets/pint.svg'
function MobileMenu() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /* const [mobileShow, setMobileShow] = useState(false);
    const showDropdown = (e)=>{
        setMobileShow(!show);
    }
    const hideDropdown = e => {
        setMobileShow(false);
    } */
    return(
        <Navbar bg="transparent" expand="lg" className="mobile-menu">
        <Container>
            <Row className="mobile-menu-w-100">
                <Col xs="3" sm='3' className="self-center">
                   <Navbar.Toggle aria-controls="navbarScroll" onClick={handleShow} />
                </Col>
                <Col xs="6" className="self-center">
                    <div className="logo">
                        <Image src={Logo} alt="Logo" title="Logo" />
                        </div>
                </Col>
                <Col xs="3" className="self-center">
                    <div className="whatsapp-icon text-end">
                        <Image src={WhatsappIcon} alt="WhatsappIcon" title="WhatsappIcon" />
                    </div>
                </Col>
            </Row>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Nav.Link href={process.env.PUBLIC_URL+'/#shop'}>Shop</Nav.Link>
                <NavDropdown title="Collections" className="dropdown">
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/earrings'}><span>Earings</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/haarams'}>
                    <span>Haarams</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item  href={process.env.PUBLIC_URL+'/product/necklace'}><span>Necklace</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href={process.env.PUBLIC_URL+'/product/vaddanam'}>
                    <span>Vaddanam</span> <strong><Image src={RightIcon} alt="RightIcon" title="RightIcon" className="dropdown-right-icon" /></strong>
                    </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href={process.env.PUBLIC_URL+'/#custom-jewlery'}>
                  Custom Jewelry 
                </Nav.Link>
                <Nav.Link href={process.env.PUBLIC_URL+'/#faq'}>
                  FAQ
                </Nav.Link>
                <ul className='social-icons'>
                <li><a href="https://www.facebook.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Facebook} alt="Facebook" title="Facebook" /></a></li>
                            <li><a href="https://www.instagram.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Instagram} alt="Instagram" title="Instagram" /></a></li>
                            <li><a href="https://in.pinterest.com/jiannaheritage/" target="_blank" rel="noreferrer"><Image src={Twitter} alt="Twitter" title="Twitter" /></a></li>
                </ul>
                <div className='contact-info'>
                    <p className='small-margin'>
                        <span><Image src={PhoneCall} alt="" title="" /></span>
                        +1 (832) 846-0004
                    </p>
                    <p className='small-margin'>Mon-Sat: 11am - 6:00pm</p>
                    <p>Sun: Closed</p>
                </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    </Navbar>
    )
}
export default MobileMenu;