import Facebook from '../../assets/facebook-colored.svg'
import Instagram from '../../assets/insta-colored.svg'
import Twitter from '../../assets/pint.svg'
import './Header.scss';
import Logo from '../../assets/logo.png'
import PhoneCall from '../../assets/phone-call.png'
import { Container, Row,Col,Image } from "react-bootstrap";
function Header() {
    return(
        <section className="header">
            <Container>
                <Row>
                    <Col md="2" lg="2" sm="2" xs="12" className='self-end'>
                        <ul className='social-icons'>
                            <li><a href="https://www.facebook.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Facebook} alt="Facebook" title="Facebook" /></a></li>
                            <li><a href="https://www.instagram.com/JiannaJewelers/" target="_blank" rel="noreferrer"><Image src={Instagram} alt="Instagram" title="Instagram" /></a></li>
                            <li><a href="https://in.pinterest.com/jiannaheritage/" target="_blank" rel="noreferrer"><Image src={Twitter} alt="Twitter" title="Twitter" /></a></li>
                        </ul>
                    </Col>
                    <Col md="7" lg="7" sm="7" xs="12" className='self-end'>
                        <div className="logo" onClick={()=>window.location.href=`/`}>
                           <Image src={Logo} alt="Logo" title="Logo"  />
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12" className='self-end'>
                        <div className='contact-info'>
                            <p className='small-margin'>
                                <span><Image src={PhoneCall} alt="" title="" /></span>
                                +1 (832) 846-0004
                            </p>
                            <p className='small-margin'>Mon-Sat: 11am - 6:00pm</p>
                            <p>Sun: Closed</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Header;